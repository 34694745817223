import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  // {
  //   pid: 0,
  //   risk: 5,
  //   lpSymbol: 'VERT-USDC QLP',
  //   lpAddresses: {
  //     80001: '',
  //     137: '0x668269d6E5D2c2dE31D132Ac218044211643622B',
  //   },
  //   tokenSymbol: 'VERT',
  //   tokenAddresses: {
  //     80001: contracts.vert[80001],
  //     137: contracts.vert[137],
  //   },
  //   quoteTokenSymbol: QuoteToken.USDC,
  //   quoteTokenAddresses: contracts.usdc,
  //   exchange:"QuickSwap"

  // },
 
]

export default farms
