import BigNumber from 'bignumber.js'
import contracts from './contracts'
import { VaultConfig, QuoteToken } from './types'

const vaults: VaultConfig[] = [
 

]

export default vaults
